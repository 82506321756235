@import '../../globals.module.scss';

.launchDateDiv {
	background-color: $launch-header-color;
	display: flex;
	flex-direction: column;
	align-items: center;

	.launchDateHeaderDiv {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		height: 71px;
		max-width: 754px;
		padding-top: 18px;
		padding-bottom: 18px;

		@include mq414px {
			padding-right: 2vw;
			padding-left: 2vw;
		}

		.tokenCoinImg {
			@include mq740px {
				width: 14px;
				height: 14px;
			}

			@include mq414px {
				min-width: 14px;
				min-height: 14px;
			}
		}

		p {
			font-family: 'Lufga-semi-bold';
			font-size: 20px;
			color: white;
			text-align: center;
			margin: 0 20px;
			white-space: nowrap;

			&:hover {
				filter: brightness(0.8);
			}

			@include mq740px {
				font-size: 3vw;
			}

			@include mq414px {
				// font-size: 12px !important;
				// word-break: normal;
				// white-space: normal;
				margin: 0 2%;
			}
		}
	}
}
