@import '../globals.module.scss';

.footerOuterDiv {
	margin-top: 3rem;
	background-color: rgb(3, 31, 28);
	border-top: 1px solid #f1eacf;
	.footerInnerDiv {
		margin: 0 auto;
		width: 100%;
		max-width: 2650px;
		display: flex;
		flex-direction: row;
		padding-top: 4%;

		@include mq1024px {
			margin-bottom: 5%;
		}

		.treeSetOne {
			flex: 0.75;
			position: relative;
			width: 100%;
			overflow: hidden;

			@include mq1155px {
				flex: 0.6;
			}

			@include mq1024px {
				display: none;
			}

			.treeNumOne {
				transform: translateY(32px);
				position: absolute;
				bottom: 0;
				left: 0;
				width: 60%;

				@include mq2200px {
					width: 70%;
				}

				@include mq1155px {
					display: none;
				}
			}

			.treeNumTwo {
				width: 60%;
				position: absolute;
				bottom: 0;
				right: 0;

				@include mq2200px {
					width: 70%;
				}

				@include mq1155px {
					bottom: 0;

					width: 300px;
					left: 0;
				}
			}
		}

		.footerContent {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;

			.footerChumbiLogo {
				.chumbiLogoBig {
					@include mq1600px {
						width: 35vw;
						height: 15.54vw;
					}

					@include mq834px {
						width: 45vw;
						height: 19.98vw;
					}

					@include mq550px {
						width: 60vw;
						height: 26.64vw;
					}
				}
			}

			.inputAndSubscribeBtn {
				width: 92%;
				margin-top: 4%;
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				align-items: center;

				@include mq1024px {
					// justify-content: center;
					display: block;
				}

				@include mq834px {
					margin-top: 2%;
				}

				form {
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					align-items: center;

					@include mq1024px {
						justify-content: center;
					}

					@include mq450px {
						flex-direction: column;
						align-items: center;
					}

					.inputEmail {
						height: 65px;
						width: 66%;
						padding: 21px 38px 19px 38px;
						border-radius: 16px;
						outline: none;
						border: none;
						font-family: 'Lufga-regular';
						font-size: 22px;
						line-height: 20px;
						letter-spacing: 0;
						text-align: left;
						background-color: rgba(255, 255, 255, 0.9);

						&:focus {
							background-color: rgba(255, 255, 255, 1);
						}

						@include mq1400px {
							font-size: 20px;
						}

						@include mq1155px {
							height: 55px;
						}

						@include mq1024px {
							width: 40%;
							margin-right: 20px;
						}

						@include mq550px {
							height: 50px;
							font-size: 16px;
							padding: 6px 16px 6px 16px;
							width: 50%;
						}

						@include mq450px {
							margin-right: 0;
							margin-bottom: 10px;
						}

						&::placeholder {
							color: rgba(35, 35, 35, 0.7);
						}
					}

					.subscribeBtn {
						outline: none;
						border: none;
						width: 30%;
						height: 65px;
						background: rgba(103, 166, 94, 0.7);
						border-radius: 16px;
						color: white;
						font-family: 'Lufga-bold';
						font-size: 24px;
						line-height: 36px;
						transition: all 0.2s ease;

						&:hover {
							text-decoration: none;
							transform: scale(1.1);
						}

						&:active {
							transform: translateY(5px);
						}

						@include mq1400px {
							font-size: 20px;
						}

						@include mq1155px {
							height: 55px;
						}

						@include mq1024px {
							width: 20%;
						}
						@include mq550px {
							height: 50px;
							font-size: 14px;
							width: 30%;
						}
					}
				}
			}

			.socialsMediaIcons {
				width: 60%;
				margin: 7% 0;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				@include mq1500px {
					width: 70%;
				}

				@include mq1300px {
					width: 75%;
				}

				@include mq1200px {
					width: 80%;
				}

				@include mq1024px {
					width: 55%;
				}

				@include mq700px {
					width: 60%;
				}

				@include mq600px {
					width: 65%;
				}

				@include mq550px {
					width: 75%;
				}

				@include mq450px {
					width: 85%;
				}

				.mediaIconsBtn {
					outline: none;
					border: none;
					background-color: transparent;
					transition: all 0.2s ease-out;

					&:hover {
						text-decoration: none;
						transform: scale(1.1);
					}

					&:active {
						transform: translateY(5px);
					}

					.mediaIconsSvg {
						@include mq550px {
							width: 7vw;
						}
					}
				}
			}

			.chumbiInfoTextDiv {
				display: flex;
				flex-direction: row;
				color: #f1eacf;

				.chumbiInfoTextNew {
					font-family: 'Lufga-regular';
					font-size: 20px;
					line-height: 54px;
					letter-spacing: 1px;
					text-align: center;

					@include mq550px {
						font-size: 16px;
					}

					&:hover {
						filter: brightness(85%);
						text-decoration: 2px underline solid;
					}
				}
			}
		}

		.treeSetTwo {
			flex: 0.75;
			position: relative;
			width: 100%;
			overflow: hidden;

			@include mq1155px {
				flex: 0.6;
			}

			@include mq1024px {
				display: none;
			}

			.treeNumThree {
				z-index: 100;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 60%;

				@include mq2200px {
					width: 70%;
				}
				@include mq1155px {
					bottom: 0;
					width: 300px;
					left: 0;
				}
			}

			.treeNumFour {
				transform: translateY(32px);
				position: absolute;
				bottom: 0;
				right: 0;
				width: 60%;

				@include mq2200px {
					width: 70%;
				}

				@include mq1155px {
					display: none;
				}
			}
		}
	}
}
