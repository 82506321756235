// colors
$launch-header-color: rgb(41, 92, 92);
$text-color: #f1eacf;
// media queries

@mixin mq2200px {
	@media (max-width: 2200px) {
		@content;
	}
}

@mixin mq1750px {
	@media (max-width: 1750px) {
		@content;
	}
}

@mixin mq1670px {
	@media (max-width: 1670px) {
		@content;
	}
}

@mixin mq1600px {
	@media (max-width: 1600px) {
		@content;
	}
}

@mixin mq1500px {
	@media (max-width: 1500px) {
		@content;
	}
}

@mixin mq1440px {
	@media (max-width: 1440px) {
		@content;
	}
}

@mixin mq1400px {
	@media (max-width: 1500px) {
		@content;
	}
}

@mixin mq1360px {
	@media (max-width: 1360px) {
		@content;
	}
}

@mixin mq1355px {
	@media (max-width: 1355px) {
		@content;
	}
}

@mixin mq1300px {
	@media (max-width: 1300px) {
		@content;
	}
}

@mixin mq1280px {
	@media (max-width: 1280px) {
		@content;
	}
}

@mixin mq1240px {
	@media (max-width: 1240px) {
		@content;
	}
}

@mixin mq1200px {
	@media (max-width: 1200px) {
		@content;
	}
}

@mixin mq1170px {
	@media (max-width: 1170px) {
		@content;
	}
}

@mixin mq1155px {
	@media (max-width: 1155px) {
		@content;
	}
}

@mixin mq1100px {
	@media (max-width: 1100px) {
		@content;
	}
}

@mixin mq1024px {
	@media (max-width: 1024px) {
		@content;
	}
}

@mixin mq1140px {
	@media (max-width: 1140px) {
		@content;
	}
}

@mixin mq980px {
	@media (max-width: 946px) {
		@content;
	}
}

@mixin mq946px {
	@media (max-width: 946px) {
		@content;
	}
}

@mixin mq940px {
	@media (max-width: 940px) {
		@content;
	}
}

@mixin mq900px {
	@media (max-width: 940px) {
		@content;
	}
}

@mixin mq890px {
	@media (max-width: 890px) {
		@content;
	}
}

@mixin mq834px {
	@media (max-width: 834px) {
		@content;
	}
}

@mixin mq800px {
	@media (max-width: 800px) {
		@content;
	}
}

@mixin mq768px {
	@media (max-width: 768px) {
		@content;
	}
}

@mixin mq740px {
	@media (max-width: 740px) {
		@content;
	}
}

@mixin mq700px {
	@media (max-width: 700px) {
		@content;
	}
}

@mixin mq654px {
	@media (max-width: 654px) {
		@content;
	}
}

@mixin mq600px {
	@media (max-width: 600px) {
		@content;
	}
}

@mixin mq550px {
	@media (max-width: 550px) {
		@content;
	}
}

@mixin mq530px {
	@media (max-width: 530px) {
		@content;
	}
}

@mixin mq500px {
	@media (max-width: 500px) {
		@content;
	}
}

@mixin mq480px {
	@media (max-width: 480px) {
		@content;
	}
}

@mixin mq450px {
	@media (max-width: 450px) {
		@content;
	}
}

@mixin mq414px {
	@media (max-width: 414px) {
		@content;
	}
}

@mixin mq400px {
	@media (max-width: 400px) {
		@content;
	}
}

@mixin mq375px {
	@media (max-width: 375px) {
		@content;
	}
}

@mixin mq360px {
	@media (max-width: 360px) {
		@content;
	}
}

@mixin mq340px {
	@media (max-width: 340px) {
		@content;
	}
}

@mixin mq320px {
	@media (max-width: 320px) {
		@content;
	}
}
