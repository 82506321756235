@import '../../globals.module.scss';

.headerTransparentOuterDiv {
	display: flex;
	flex-direction: column;
	transition: all 0.4s linear;

	header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0 auto;
		height: fit-content;
		width: 100%;
		padding-top: 50px;
		padding-right: 10%;
		padding-left: 10%;
		position: relative;
		max-width: 2300px;

		@include mq2200px {
			max-width: 2100px;
		}

		@include mq1670px {
			max-width: 1610px;
			padding-right: 5%;
			padding-left: 5%;
		}

		@include mq600px {
			padding-top: 10px;
		}

		.chumbiLogoDiv {
			position: relative;
			z-index: 1000;
			.chumbiLogoSvg {
				@include mq740px {
					width: 167px;
					height: 73px;	
				}
				@include mq600px {
					width: 145px;
					height: 63px;
				}
			}
		}

		.navLinksDiv {
			
			font-family: 'BoldenVan';
			font-size: 20px;
			color: #66a65e;
			list-style: none;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 62%;
			letter-spacing: 1px;
			text-transform: uppercase;
			text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.251);

			@include mq1670px {
				font-size: 16px;
			}

			@include mq1240px {
				font-size: 15px;
			}

			@include mq1140px {
				display: none;
			}

			.activeNavLink {
				color: white;
			}

			.navLink {
				transition: 0.2s color ease-in-out;
				cursor: pointer;

				&:hover {
					color: darken($color: #fff, $amount: 20);
				}
			}

			.shakeAnimation {
				animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
				transform: translate3d(0, 0, 0);

				@keyframes shake {
					10%,
					90% {
						transform: translate3d(-1px, 0, 0);
					}

					20%,
					80% {
						transform: translate3d(2px, 0, 0);
					}

					30%,
					50%,
					70% {
						transform: translate3d(-4px, 0, 0);
					}

					40%,
					60% {
						transform: translate3d(4px, 0, 0);
					}
				}
			}

			.connectWalletBtn {
				border: none;
				outline: none;
				width: fit-content;
				background-color: rgb(29, 102, 191);
				font-family: 'Lufga-medium';
				font-size: 20px;
				color: white;
				border-radius: 12px;
				letter-spacing: 1px;
				padding: 10px 20px;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: all 0.2s ease;

				@include mq1670px {
					font-size: 16px;
				}

				@include mq1240px {
					font-size: 15px;
				}

				@include mq1140px {
					display: none;
				}

				&:hover {
					text-decoration: none;
					transform: scale(1.05);
				}

				&:active {
					transform: translateY(5px);
				}

				.connectWalletIcon {
					height: 20px;
				}
			}
		}

		.hamburgerDiv {
			display: none;
			@include mq1140px {
				display: block;
				cursor: pointer;
				z-index: 1000;
				position: relative;
			}
		}
	}

	.navLinksDropdown {
		position: absolute;
		width: 100%;
		top:0;
		min-height:100vh ;
		padding:  15rem 3% 20rem 3%;
		background: rgba(4, 36, 33, 0.4);
backdrop-filter: blur(24px);
		z-index: 500;
		display: flex;
		flex-direction: column;
		font-family: 'BoldenVan';
		list-style: none;
		font-size: 16px;
		color: rgb(100, 167, 92);
		justify-content: space-around;
		align-items: center;
		text-transform: uppercase;
		cursor: pointer;
		gap: 20px;
		letter-spacing: 1px;

		@include mq740px {
			padding:  15rem 3% 20rem 3%;
		}

		@include mq600px {
			padding:  10rem 3% 25rem 3%;
		}

		.shakeAnimation {
			animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
			transform: translate3d(0, 0, 0);

			@keyframes shake {
				10%,
				90% {
					transform: translate3d(-1px, 0, 0);
				}

				20%,
				80% {
					transform: translate3d(2px, 0, 0);
				}

				30%,
				50%,
				70% {
					transform: translate3d(-4px, 0, 0);
				}

				40%,
				60% {
					transform: translate3d(4px, 0, 0);
				}
			}
		}

		.activeNavLink {
			color: white;
		}

		.navLink {
			transition: 0.2s color ease-in-out;
			&:hover {
				color: darken($color: #fff, $amount: 20);
			}
		}
		.connectWalletBtn {
			border: none;
			outline: none;
			width: fit-content;
			background-color: rgb(29, 102, 191);
			font-family: 'Lufga-medium';
			font-size: 20px;
			color: white;
			border-radius: 12px;
			letter-spacing: 1px;
			padding: 10px 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all 0.2s ease;

			@include mq1670px {
				font-size: 16px;
			}

			@include mq1240px {
				font-size: 15px;
			}

			&:hover {
				text-decoration: none;
				transform: scale(1.05);
			}

			&:active {
				transform: translateY(5px);
			}

			.connectWalletIcon {
				height: 20px;
			}
		}
	}
}

.connectWalletBtn {
	border: none;
	outline: none;
	width: fit-content;
	background-color: rgb(29, 102, 191);
	font-family: 'Lufga-medium';
	font-size: 20px;
	color: white;
	border-radius: 12px;
	letter-spacing: 1px;
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease;

	@include mq1670px {
		font-size: 16px;
	}

	@include mq1240px {
		font-size: 15px;
	}

	&:hover {
		text-decoration: none;
		transform: scale(1.05);
	}

	&:active {
		transform: translateY(5px);
	}

	.connectWalletIcon {
		height: 20px;
	}
}
