@font-face {
	font-family: 'BoldenVan';
	src: url('../fonts/BoldenVan.otf');
}

@font-face {
	font-family: 'Lufga-thin';
	src: url('../fonts/Lufga-Thin.otf');
}

@font-face {
	font-family: 'Lufga-thin-italic';
	src: url('../fonts/Lufga-ThinItalic.otf');
}

@font-face {
	font-family: 'Lufga-semi-bold';
	src: url('../fonts/Lufga-SemiBold.otf');
}

@font-face {
	font-family: 'Lufga-semi-bold-italic';
	src: url('../fonts/Lufga-SemiBoldItalic.otf');
}

@font-face {
	font-family: 'Lufga-regular';
	src: url('../fonts/Lufga-Regular.otf');
}

@font-face {
	font-family: 'Lufga-medium';
	src: url('../fonts/Lufga-Medium.otf');
}

@font-face {
	font-family: 'Lufga-medium-italic';
	src: url('../fonts/Lufga-MediumItalic.otf');
}

@font-face {
	font-family: 'Lufga-light';
	src: url('../fonts/Lufga-Light.otf');
}

@font-face {
	font-family: 'Lufga-light-italic';
	src: url('../fonts/Lufga-LightItalic.otf');
}

@font-face {
	font-family: 'Lufga-italic';
	src: url('../fonts/Lufga-Italic.otf');
}

@font-face {
	font-family: 'Lufga-extra-light-italic';
	src: url('../fonts/Lufga-ExtraLightItalic.otf');
}

@font-face {
	font-family: 'Lufga-extra-light';
	src: url('../fonts/Lufga-ExtraLight.otf');
}

@font-face {
	font-family: 'Lufga-extra-bold';
	src: url('../fonts/Lufga-ExtraBold.otf');
}

@font-face {
	font-family: 'Lufga-extra-bold-italic';
	src: url('../fonts/Lufga-ExtraBoldItalic.otf');
}

@font-face {
	font-family: 'Lufga-bold-italic';
	src: url('../fonts/Lufga-BoldItalic.otf');
}

@font-face {
	font-family: 'Lufga-bold';
	src: url('../fonts/Lufga-Bold.otf');
}

@font-face {
	font-family: 'Lufga-black-italic';
	src: url('../fonts/Lufga-BlackItalic.otf');
}

@font-face {
	font-family: 'Lufga-black';
	src: url('../fonts/Lufga-Black.otf');
}
