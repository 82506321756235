@import '../../globals.module.scss';

.homePageDiv {
	display: flex;
	flex-direction: column;
	background: none;

	.divForSettingBgImageOne {
		background: none;
	}
}

@media screen and (max-width: 1024px) {
	.breed {
		background-image: none;
		background-color: #042421;
	}
}

.shakeAnimation {
	animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
	transform: translate3d(0, 0, 0);

	@keyframes shake {
		10%,
		90% {
			transform: translate3d(-1px, 0, 0);
		}

		20%,
		80% {
			transform: translate3d(2px, 0, 0);
		}

		30%,
		50%,
		70% {
			transform: translate3d(-4px, 0, 0);
		}

		40%,
		60% {
			transform: translate3d(4px, 0, 0);
		}
	}
}

.connectWalletBtn {
	border: none;
	outline: none;
	width: fit-content;
	background-color: rgb(29, 102, 191);
	font-family: 'Lufga-medium';
	font-size: 20px;
	color: white;
	border-radius: 12px;
	letter-spacing: 1px;
	padding: 10px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease;

	@include mq1670px {
		font-size: 16px;
	}

	@include mq1240px {
		font-size: 15px;
	}

	@include mq1140px {
		display: none;
	}

	&:hover {
		text-decoration: none;
		transform: scale(1.05);
	}

	&:active {
		transform: translateY(5px);
	}

	.connectWalletIcon {
		height: 20px;
	}
}
