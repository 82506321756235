@import './globals.module.scss';
@import './styles/Fonts.module.scss';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body {
	font-family: 'Roboto';
	width: 100%;
	overflow-x: hidden;
}

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	background-color: #001f1b;
}

button {
	cursor: pointer;
}

a {
	color: inherit;
	text-decoration: none;
}

input#clean {
	border: none;
	background-color: transparent;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&[type='number'] {
		-moz-appearance: textfield;
	}
	&[type='checkbox'] {
		cursor: pointer;
	}
	&:focus {
		outline: none;
	}
}
